.loader {
    border: 8px solid #f3f3f3; /* Lighter grey for a more subtle look */
    border-top: 8px solid #3498db; /* Blue to keep it vibrant but less intense */
    border-radius: 50%;
    width: 60px; /* Reduced size for a more refined appearance */
    height: 60px;
    animation: spin 1s linear infinite; /* Faster spin for a more dynamic feel */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
