/* Overlay for dimmed background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Drawer styles */
.user-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 90%;
  max-width: 400px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  overflow-y: auto;
  border-left: 3px solid #ff6600;
}

.user-drawer.open {
  transform: translateX(0);
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #007acc;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8080805e;
  padding: 10px 20px;
}

.logo {
  padding: 15px;
  width: 130px;
}
/* Content Styling */
.drawer-content {
  margin-top: 40px;
  padding-bottom: 20px;
}

/* Section Titles */
.section-title {
  font-size: 22px;
  color: #003366;
  border-bottom: 2px solid #ff6600;
  margin-bottom: 20px;
}

/* Profile Sections */
.profile-section, .inhabitants-section, .financial-section, .listing-section {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.inhabitants-section {
  background-color: #f0f0f5;
}

.inhabitant-card {
  background-color: #e6f7ff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border-left: 4px solid #007acc;
}

/* Text Styling */
.profile-item p, .inhabitant-card p {
  margin: 8px 0;
  color: #333;
  font-size: 16px;
}

.profile-item strong {
  color: #003366;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .user-drawer {
      width: 400px;
  }
}


.profile-header {
  display: flex;
  align-items: center; /* Vertically center align the items */
  gap: 10px; /* Space between image and text */
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #002f6c; /* Adjust to match the immobilescout24 color palette */
}
