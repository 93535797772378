/* src/PropertyDetails.css */

.property-details {
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    background-color: #fff;
    font-family: Arial, sans-serif;
    color: #333;
    padding-left: 10px;
}

.price-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.price-row {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.price {
    color: #333;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url('../public/priority-line-10.svg');
font-size: 22px;
    background-size: cover;
    padding: 0 4px;
    
}
.price2{
    padding:0 21px;
}
.price23{
    font-size: smaller;
    margin-top: 15px;
    color:#757575;
}

.price234{
    color: #333;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    
font-size: 22px;
    background-size: cover;
    padding: 0 21px;
}
.subtext, .warm-price-label {
    font-size: 12px;
    color: #777;
}

.info-columns {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #333;
    margin: 10px 0;
}

.warm-price {
    font-size: 17px;
    font-weight: bold;
}

.property-title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin: 15px 0;
    padding-left: 10px;
}

.address-section {
    margin-bottom: 20px;
}

.section-label {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    padding-left: 10px;
}

.address-details {
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: #333;
    padding-left: 3px;
}

.icon {
    font-size: 16px;
    color: #2a9d8f;
}

.map-link {
    display: flex;
    align-items: center;
    color: #2a9d8f;
    font-size: 12px;
    margin-top: 5px;
    text-decoration: none;
}

.features {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.feature {
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    color: #555;
}

.details {
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 14px;
}

.detail-title {
    color: #333;
    font-weight: bold;
}

.detail-info {
    color: #555;
}

.link {
    color: #2a9d8f;
    text-decoration: none;
}

.titleListing {
    font-family: Make It Sans IS24 Web, Verdana, DejaVu Sans, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
}

.addressSVG{
    width: 13%; 
} 

.details{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}


/* src/PropertyDetails.css */
.sticky-reserve-button {
    position: fixed; /* Make it sticky at the bottom */
    bottom: 0; /* Position it at the bottom */
    left: 0;
    right: 0;
    width: 100%; /* Full width */
    z-index: 100; /* Ensure it appears above other content */
    background-color: #00d9b1;
    border-color: #00d9b1;
    color: #333;
    padding: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: 0 -2px 10px #00d9b1;
}

.sticky-reserve-button:hover {
    background-color: #00d88f; /* Slightly darker on hover */
}
