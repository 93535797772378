/* Main Image Container */
.body-container {
    padding: 0px;
}

.main-image-container {
    position: relative;
    cursor: pointer;
}

.main-image {
    width: 100%;
    border-radius: 0px;
}

.image-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
}

/* Modal Styles */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 40px;
    color: #000000;
    cursor: pointer;
}

.image-modal .close-icon{
    color:white;
}
.modal-counter {
    position: absolute;
    top: 15px;
    left: 15px;
    color: white;
    font-size: 18px;
}

.modal-title {
    position: absolute;
    top: 15px;
    font-size: 18px;
    color: white;
    margin-top: 40px;
}

.fullscreen-image {
    width: 100%;
    height: auto;
    max-height: 80vh;
    margin-top: 20px;
}

.modal-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.modal-nav.left {
    left: 20px;
}

.modal-nav.right {
    right: 20px;
}
