/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
}

.App {
   
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    /* padding: 30px; */
    
    animation: fadeIn 0.6s ease-in-out;
}

h2 {
    text-align: center;
    color: #343a40;
    margin-bottom: 30px;
    font-size: 1.8rem;
}

.terms-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
}
.spinner {
    border: 16px solid #f3f3f3; /* Light grey background */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.line-height30{
    line-height: 30px;
}
.terms-item {
    display: flex;
    align-items: flex-start;
    background-color: #f1f3f5;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s ease;
}

.terms-item:hover {
    background-color: #e9ecef;
}

input[type="checkbox"] {
    transform: scale(1.4);
    margin-right: 15px;
    cursor: pointer;
    accent-color: #007bff;
    
}

label {
    font-size: 1rem;
    color: #495057;
    cursor: pointer;
    line-height: 1.4;
}

.submit-button {
    margin-top: 30px;
    padding: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #6c757d;
    border: none;
    border-radius: 8px;
    cursor: not-allowed;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: block;
    width: 80%;
    padding-left: 30px;
    padding-right: 30px;
    margin-left:10%;
    
   
}

.submit-button.active {
    background-color: #28a745;
    cursor: pointer;
}

.submit-button.active:hover {
    background-color: #218838;
    transform: translateY(-3px);
}


.checkbox-container {
    padding-left: 30px;
    padding-right: 30px;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .App {
        width: 100%;
    }

    h2 {
        font-size: 1.5rem;
    }

    label {
        font-size: 0.95rem;
    }

    .submit-button {
        font-size: 1rem;
    }
}
