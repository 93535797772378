.signature-container {
    text-align: center;
    margin: 20px auto;
    max-width: 500px;
}

.signature-canvas {
    border: 2px solid #ccc;
    border-radius: 8px;
    width: 100%;
    height: 200px;
    cursor: crosshair;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

button {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
